body {
   background: #f4f9fc;
background: radial-gradient(at center, #f6fbfe, #edf4fb);

}

 .home_hero {
   color: gray;
    width: 100%;
   
    padding-top: 75px;
    margin:0 auto !important;


  overflow: hidden;
 }

 .home_hero h1:first-child {
   
    display: inline;
    font-size: 50px;
    text-align: center;
    padding-top: 100px;
    color: #0074ad;
    font-weight: bold;
    padding-bottom: 15px;
 }
 .home_hero h1 {
  
    display: inline;
    font-size: 50px;
    text-align: center;
    padding-top: 10px;
    color: #0bafe6;
    font-weight: 600;

 }
 .home_hero h2 {
   color: gray;
    display: inline;
    margin: 10px auto;
    font-size: 50px;
    text-align: center;

    font-weight: 600;
    padding: 10px;
 }



 .fueatures_img {
   width:200px;
   height:200px;
 }


 .home_header {
   width: 100%;
   height: 95px;
   text-align: center;
   position: fixed;
   z-index: 999;
   top: 0;
   left: 0;
   padding-top: 20px;
      padding-right: 20px;
   display: flex;
   justify-content: start;

}

.home_header img{
   width: 180px;
   height: 65px;

}
.card_wssp{
   margin: 0px 40px;
   width: 150px !important;
    height:150px !important;
     cursor: pointer
}
.card_wssp img{
   width: 50px !important;
    height: 50px !important;

}
.card_wssp .img01{
   width: 70px !important;
    height: 35px !important;
   margin-top: 15px;
}
.card_wssp .img02{
   width: 100px !important;
    height: 80px !important;
    margin-top: 0px;
}
.card_wssp span {
   font-weight: bold;
    font-size: 20px;
     margin-top: 20px;
}


.span4 {
   color: #fff;
   font-size:15px  !important;
   margin-top:0  !important
}


.testimonials_card {
   max-width: 75%;
    margin: 0 auto
}
@media only screen and (max-width:1000px) {
   .card_wssp{
      margin-bottom: 30px;
      width: 150px !important;
       height: 150px !important;
        cursor: pointer
   }
   .card_wssp img{
      width: 65px !important;
       height: 65px !important;
   
   }
   .card_wssp .img01{
      width: 100px !important;
       height: 50px !important;
      margin-top: 15px;
   }
   .card_wssp .img02{
      width: 100px !important;
       height: 80px !important;
       margin-top: 0px;
   }
   .card_wssp span {
      font-weight: bold;
       font-size: 15px;
        margin-top: 20px;
   }
   .home_hero h1:first-child {
      font-size: 30px;
       text-align: center;
       color: #0074ad;
       padding-top: 50px;
       font-weight: 800;
    }
    .home_hero h1 {
      
       font-size: 30px;
       text-align: center;
       padding-top: 10px;
       color: #0bafe6;
       font-weight: bold;


    }
    .span4 {
      color: #fff;
      font-size:15px  !important;
      margin-top:0  !important
   }
 }
 @media only screen and (max-width:500px) {
   .home_hero h1:first-child {
      font-size: 25px;
       text-align: center;
       padding-top: 30px;
       color: #0074ad;

       font-weight: bold;
    }
    .home_hero h1 {
      
       font-size: 30px;
       text-align: center;
       color: #0bafe6;
       font-weight: bold;


    }
    .home_header {
      width: 100%;
      height: 75px;
      text-align: center;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      padding-top: 20px;
      padding-right: 20px;
   }

    .home_hero h2 {
       font-size: 25px;
       text-align: center;
       color: white;
       font-weight: 600;
       margin: 10px auto;
       padding: 10px;
    }

    .fueatures_img {
      width:150px;
      height:150px;
      margin: 0 auto;
    }


    .card_wssp{
      margin-bottom: 30px;
      width: 150px !important;
       height: 150px !important;
        cursor: pointer
   }
   .card_wssp img{
      width: 65px !important;
       height: 65px !important;
   
   }
   .card_wssp .img01{
      width: 100px !important;
       height: 50px !important;
      margin-top: 15px;
   }
   .card_wssp .img02{
      width: 100px !important;
       height: 80px !important;
       margin-top: 0px;
   }
   .card_wssp span {
      font-weight: bold;
       font-size: 15px;
        margin-top: 20px;
   }
   .home_header img {
      width: 130px;
      height: 40px;
  }
  .span4 {
   color: #fff;
   font-size:15px !important;
   margin-top:0 !important
} 
 }
 @media only screen and (max-width:400px) {
   .home_hero h1:first-child {
      font-size: 22px;
       text-align: center;
       padding-top: 30px;
       color: #0074ad;

       font-weight: bold;
    }
    .home_hero h1 {
      
       font-size: 25px;
       text-align: center;
       color: #0bafe6;
       font-weight: bold;


    }
    .home_header {
      width: 100%;
      height: 75px;
      text-align: center;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      padding-top: 20px;
      padding-right: 20px;
   }
/* 
    .home_hero h2 {
       font-size: 25px;
       text-align: center;
       color: white;
       font-weight: 600;
       margin: 10px auto;
       padding: 10px;
    } */

    .fueatures_img {
      width:150px;
      height:150px;
      margin: 0 auto;
    }


    .card_wssp{
      margin-bottom: 25px;
      width: 130px !important;
       height: 130px !important;
        cursor: pointer
   }
   .card_wssp img{
      width: 65px !important;
       height: 65px !important;
   
   }
   .card_wssp .img01{
      width: 100px !important;
       height: 50px !important;
      margin-top: 15px;
   }
   .card_wssp .img02{
      width: 100px !important;
       height: 80px !important;
       margin-top: 0px;
   }
   .card_wssp span {
      font-weight: bold;
       font-size: 15px;
        margin-top: 20px;
   }
   .home_header img {
      width: 130px;
      height: 40px;
  }
  
.span4 {
   color: #fff;
   font-size:12px;
   margin-top:0 !important
}
.testimonials_card {
   max-width: 90%;
    margin: 0 auto
}
 }
 
 




 