/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    z-index: 997;
    transition: all 0.5s;
    padding: 22px 0;
    background: #fff;
    margin-bottom:50px;
}

#header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
}

#header .logo h1 {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #222222;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.nav-link {
    cursor: pointer;
}
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    font-weight: 400;
    color: #555555;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 14px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #3498db;
}

.navbar .getstarted,
.navbar .getstarted:focus {
    background: #3498db;
    padding: 8px 25px;
    margin-left: 30px;
    border-radius: 50px;
    color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
    color: #fff;
    background: #4aa3df;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 30px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #3498db;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
* Mobile Navigation
*/

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 70vh;
    margin-top: 70px;
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: black;
}

#hero h2 {
    color: #484848;
    margin-bottom: 50px;
    font-size: 24px;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #3498db;
    border: 2px solid #3498db;
}

#hero .btn-get-started:hover {
    background: #3498db;
    color: #fff;
}

#hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    #hero {
        height: 100vh;
    }

    #hero .animated {
        -webkit-animation: none;
        animation: none;
    }

    #hero .hero-img {
        text-align: center;
    }

    #hero .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #hero {
        margin-top: 20px;
    }

    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    #hero .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #hero .hero-img img {
        width: 80%;
    }
}

@-webkit-keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}


/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #f7fbfe;
}

.section-title {
    text-align: center;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    color: #222222;
}

.section-title h2::before,
.section-title h2::after {
    content: "";
    width: 50px;
    height: 2px;
    background: #3498db;
    display: inline-block;
}

.section-title h2::before {
    margin: 0 15px 10px 0;
}

.section-title h2::after {
    margin: 0 0 10px 15px;
}

.section-title p {
    margin: 15px 0 0 0;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #3498db;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.3s;
    line-height: 1;
    color: #3498db;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #3498db;
}

.about .content .btn-learn-more:hover {
    background: #3498db;
    color: #fff;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding-top: 0;
}

.counts .content {
    padding: 0;
}

.counts .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #222222;
}

.counts .content p {
    margin-bottom: 0;
}

.counts .content .count-box {
    padding: 20px 0;
    width: 100%;
}

.counts .content .count-box i {
    display: block;
    font-size: 36px;
    color: #3498db;
    float: left;
    line-height: 0;
}

.counts .content .count-box span {
    font-size: 36px;
    line-height: 30px;
    display: block;
    font-weight: 700;
    color: #222222;
    margin-left: 50px;
}

.counts .content .count-box p {
    padding: 15px 0 0 0;
    margin: 0 0 0 50px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #484848;
}

.counts .content .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #484848;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
    color: #6f6f6f;
}

@media (max-width: 1024px) {
    .counts .image {
        text-align: center;
    }

    .counts .image img {
        max-width: 70%;
    }
}

@media (max-width: 667px) {
    .counts .image img {
        max-width: 100%;
    }
}


/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
}

.services .icon-box::before {
    content: "";
    position: absolute;
    background: #e1f0fa;
    right: -60px;
    top: -40px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
}

.services .icon-box:hover::before {
    background: #3498db;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}

.services .icon {
    margin: 0 auto 20px auto;
    padding-top: 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #3498db;
    transition: all 0.3s ease-in-out;
}

.services .icon i {
    font-size: 36px;
    line-height: 1;
    color: #fff;
}

.services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .title a {
    color: #111;
}

.services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
}

.services .icon-box:hover .title a,
.services .icon-box:hover .description {
    color: #fff;
}

.services .icon-box:hover .icon {
    background: #fff;
}

.services .icon-box:hover .icon i {
    color: #3498db;
}


/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
    display: flex;
    align-items: center;
    padding: 20px;
    background: #f6f6f6;
    transition: ease-in-out 0.3s;
}

.features .icon-box i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 1;
}

.features .icon-box h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
}

.features .icon-box h3 a {
    color: #222222;
    transition: ease-in-out 0.3s;
}

.features .icon-box h3 a:hover {
    color: #3498db;
}

.features .icon-box:hover {
    background: #eef7fc;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
    padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 120px;
    border-radius: 10px;
    border: 2px solid #8d8d8d;
    position: absolute;
    left: -45px;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #e1f0fa;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3498db;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3498db;
}

@media (max-width: 767px) {
    .testimonials .testimonial-wrap {
        padding-left: 0;
    }

    .testimonials .testimonials-carousel,
    .testimonials .testimonials-slider {
        overflow: hidden;
    }

    .testimonials .testimonial-item {
        padding: 30px;
        margin: 15px;
    }

    .testimonials .testimonial-item .testimonial-img {
        position: static;
        left: auto;
    }
}


/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
    position: relative;
    overflow: hidden;
}

.team .member .social {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    opacity: 0;
    transition: ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.85);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.team .member .social a {
    transition: color 0.3s;
    color: #222222;
    margin: 0 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.team .member .social a i {
    line-height: 0;
}

.team .member .social a:hover {
    color: #3498db;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

.team .member .member-info {
    padding: 25px 15px;
}

.team .member .member-info h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;
    color: #222222;
}

.team .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

.team .member .member-info p {
    font-style: italic;
    font-size: 14px;
    line-height: 26px;
    color: #777777;
}

.team .member:hover .social {
    opacity: 1;
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 0;
    color: #222222;
    font-size: 14px;
}

#footer .credits {
    font-size: 13px;
    padding-top: 5px;
    color: #222222;
}

#footer .footer-links a {
    color: #222222;
    padding-left: 15px;
}

#footer .footer-links a:first-child {
    padding-left: 0;
}

#footer .footer-links a:hover {
    color: #3498db;
}


.p-confirm-dialog-reject {
    display: none !important;
  }
