body {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
    direction: rtl;
    height: 100%;
}

.mainN {
    background-color: #e7e9f1;
    display: flex;
    transition: all .1s ease-in-out;
    padding: 0 !important;
    width: 100% !important;
}
.sidebar-container {
    transition: all .3s ease-in-out;
}
.sidebar-container {
    width: 250px;
    height: 100vh;   
    background-color:#f8f9fe;
}

.sidebar-open {
    margin-right:0;
}
.sidebar-close{
    margin-right:-250px;
}

.main-contents {
    width: calc(100% - 250px);
    height: 100vh;
    width: 100%;
}
.sidenav-item {
    margin-bottom: 10px;
    transition: background-color .6s,color .6s,font-size .6s;
    transition-timing-function: ease-in-out;
}

.sidenav-item-active {
    background-color: #46ccf1;
}
.sidenav-item-active .sidenav-text {
    color :white;
    
}
.sidenav-item-active .sidenav-icon {
    color :white !important;
}
.sidenav-item:hover {
   background-color: #46ccf1;

}
.sidenav-item:hover>.sidenav-text {
    color :white;

}

.sidenav-item:hover .sidenav-icon {
    color :white !important;
}
.sidenav-text{
    width: 200px;
    color: rgba(0,0,0,.6);
    font-size:1.1rem;
}
@media only screen and (max-width:600px){
    .sidebar-container {
        position: fixed;
        z-index: 999;
    }
    .main-contents {
        width: 100%;
    }
}


/* nav bar */
.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;

}


/* card */
.card-stats .card {
    margin-top: 5px;
}



.icon {
    width: 3rem;
    height: 3rem;
}
.icon-shape {
    padding: 12px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.bg-gradient-red {
    background: linear-gradient(87deg,#f5365c,#f56036)!important;
}
.bg-gradient-primary {
    background: linear-gradient(87deg,#5e72e4,#825ee4)!important;
}
.bg-gradient-green {
    background: linear-gradient(87deg,#2dce89,#2dcecc)!important;
}
.bg-gradient-orange {
    background: linear-gradient(87deg,#fb6340,#fbb140)!important;
}

